import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Person from '@material-ui/icons/Person';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Modal from 'components/Modal';
import CustomButton from 'components/Button';

import { fetchUsersSituation } from '../Filter/store/services';
import { handleLogout } from '../../pages/Login/store/actions';
import { loadProfile } from '../../pages/Profile/store/thunk';

import useDidMount from 'helpers/hooks/useDidMount';
import useWindowDimensions from '../../helpers/hooks/useWindowDimensions';
import { RESTRICTION_PAGE, SITE_RESTRICTIONS } from '../../helpers/restrictions';

import projectPackage from '../../../package.json';

import { useStyles } from './styles';

const logo = require('../../assets/img/logo_header.svg');

const Header = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const didComponentMount = useDidMount();

  const windowSize = useWindowDimensions();
  const positionDropdownMenu = windowSize.width > 960 ? 'center' : 'left';

  const isResale = !!process.env.REACT_APP_RESALE_ID;

  const [usersEstablishments, setUsersEstablishments] = useState([]);
  const [defaultEstablishmentTypes, setDefaultEstablishmentTypes] = useState([]);
  const [isDebitWarningModalOpen, setIsDebitWarningModalOpen] = useState(false);
  const [, setSuperLogica] = useState([]);
  const [isLoginFromNoAccessCode] = useState(!!history.location.state?.data?.noAccessCode);

  const userRestrictions = useSelector(state => state.profile.userRestrictions);
  const { profileId } = useSelector(state => state.profile.submit);
  const { token } = useSelector(state => state.login);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishments, establishmentTypes } = useSelector(state => state.businessInfo);

  const isUserAdmin = +profileId == 1;

  const establishment = {
    isAll: !establishmentId,
    isStore: defaultEstablishmentTypes.includes(3),
    isStoreOnly: defaultEstablishmentTypes.length == 1 && +defaultEstablishmentTypes[0] == 3,
    isCarWashAndNotParking: defaultEstablishmentTypes.includes(2) && !defaultEstablishmentTypes.includes(1),
    isParkingOrCarWash: defaultEstablishmentTypes.includes(1) || defaultEstablishmentTypes.includes(2)
  }

  const premiumPackageEstablishments = usersEstablishments
    ?.filter(establishment => +establishment?.premiumPackage == 1)
    ?.map(establishment => establishment?.establishmentId);

  const debitWarningEstablishments = usersEstablishments
    ?.filter(establishment => +establishment?.appPermission == 1)
    ?.filter(establishment => +establishment?.debitWarning == 1)
    ?.map(establishment => +establishment?.establishmentId);

  const debitWarningEstablishmentNames = establishments
    ?.filter(establishment => debitWarningEstablishments?.includes(+establishment?.establishmentId))
    ?.map(establishment => establishment?.establishmentName)
    ?.join(', ');

  const isDebitWarning = debitWarningEstablishments?.length > 0;

  useEffect(() => {
    setIsDebitWarningModalOpen(isDebitWarning);
  }, [isDebitWarning, history.location.pathname]);

  useEffect(() => {
    const getData = async () => {
      const { data } = await fetchUsersSituation(userId, token);
      setSuperLogica(data?.superlogica);
      setUsersEstablishments(data?.establishments);
    }

    if(userId && !isLoginFromNoAccessCode) {
      getData();
    }
  }, [history.location.pathname, isLoginFromNoAccessCode]);

  useEffect(() => {
    if(!isLoginFromNoAccessCode) {
      if(didComponentMount) {
        dispatch(loadProfile(userId));
      }
    }
  }, [userRestrictions, isLoginFromNoAccessCode]);

  useEffect(() => {
    const establishmentTypes = usersEstablishments
      ?.find(establishment => +establishment?.establishmentId == +establishmentId)
      ?.establishmentTypes
      ?.map(establishmentTypes => +establishmentTypes) || [];

    setDefaultEstablishmentTypes(establishmentTypes);
  }, [usersEstablishments, establishmentId]);

  const [anchorBusinessEl, setAnchorBusinessEl] = React.useState(null);
  const [anchorOperationalEl, setAnchorOperationalEl] = React.useState(null);
  const [anchorProductsEl, setAnchorProductsEl] = React.useState(null);
  const [anchorFinantialEl, setAnchorFinantialEl] = React.useState(null);
  const [anchorClientsEl, setAnchorClientsEl] = React.useState(null);
  const [anchorConfigurationEl, setAnchorConfigurationEl] = React.useState(null);
  const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const businessMenuId = {
    desktop: 'business-menu-item',
    mobile: 'business-menu-mobile',
    menu: 'business-menu',
    isMenuOpen: Boolean(anchorBusinessEl)
  }

  const operationalMenuId = {
    desktop: 'operational-menu-item',
    mobile: 'operational-menu-mobile',
    menu: 'operational-menu',
    isMenuOpen: Boolean(anchorOperationalEl)
  }

  const productsMenuId = {
    desktop: 'products-menu-item',
    mobile: 'products-menu-mobile',
    menu: 'products-menu',
    isMenuOpen: Boolean(anchorProductsEl)
  }

  const finantialMenuId = {
    desktop: 'finantial-menu-item',
    mobile: 'finantial-menu-mobile',
    menu: 'finantial-menu',
    isMenuOpen: Boolean(anchorFinantialEl),
  }

  const clientsMenuId = {
    desktop: 'clients-menu-item',
    mobile: 'clients-menu-mobile',
    menu: 'clients-menu',
    isMenuOpen: Boolean(anchorClientsEl)
  }

  const configurationsMenuId = {
    desktop: 'configuration-menu-item',
    mobile: 'configuration-menu-mobile',
    menu: 'configuration-menu',
    isMenuOpen: Boolean(anchorConfigurationEl)
  }

  const profileMenuId = {
    desktop: 'profile-menu-item',
    mobile: 'profile-menu-mobile',
    menu: 'profile-menu',
    isMenuOpen: Boolean(anchorProfileEl)
  }

  const handleProductButtonLink = () => {
    if(establishment.isStoreOnly) {
      return '/novo-produto-venda';
    }

    if(establishment.isStore) {
      return '/objetivo-do-produto';
    }

    return '/novo-produto';
  }

  const userHasAllRestrictions = () => {
    return(
      userRestrictions?.includes(RESTRICTION_PAGE.CREATE_ESTABLISHMENT) &&
      userRestrictions?.includes(RESTRICTION_PAGE.CREATE_USER)
    );
  }

  const handleOpenMenu = event => {
    switch(event.currentTarget.id) {
      case businessMenuId.desktop:
        setAnchorBusinessEl(event.currentTarget);
        break;
      case operationalMenuId.desktop:
        setAnchorOperationalEl(event.currentTarget);
        break;
      case productsMenuId.desktop:
        setAnchorProductsEl(event.currentTarget);
        break;
      case finantialMenuId.desktop:
        setAnchorFinantialEl(event.currentTarget);
        break;
      case clientsMenuId.desktop:
        setAnchorClientsEl(event.currentTarget);
        break;
      case configurationsMenuId.desktop:
        setAnchorConfigurationEl(event.currentTarget);
        break;
      case profileMenuId.desktop:
        setAnchorProfileEl(event.currentTarget);
        break;
      default:
        break;
    }
  }

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  const handleMenuClose = () => {
    setAnchorBusinessEl(null);
    setAnchorOperationalEl(null);
    setAnchorProductsEl(null);
    setAnchorFinantialEl(null);
    setAnchorClientsEl(null);
    setAnchorConfigurationEl(null);
    setAnchorProfileEl(null);
  }

  const logout = () => {
    dispatch(handleLogout());
  }

  const renderBusinessMenu = (
    <Menu
      keepMounted
      anchorEl={anchorBusinessEl}
      id={businessMenuId.menu}
      open={businessMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      className={classes.menuListSectionsContent}
      MenuListProps={{ disablePadding: true }}
    >
      <Grid container>
        <Grid item xs={12} md={!userHasAllRestrictions() ? 6 : 12}>
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Controle
          </Typography>
          {(isUserAdmin || !userRestrictions?.includes(RESTRICTION_PAGE.VIEW_ESTABLISHMENT)) && (
            <MenuItem
              to="/estabelecimento"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Estabelecimento
            </MenuItem>
          )}
          {(isUserAdmin || !userRestrictions?.includes(RESTRICTION_PAGE.VIEW_USER)) && (
            <MenuItem
              to="/usuarios"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Usuários
            </MenuItem>
          )}
          {(isUserAdmin || establishment.isAll || (!establishment.isCarWashAndNotParking && !establishment.isStoreOnly)) && (
            <MenuItem
              to="/tabelas-de-preco"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Tabela de estacionamento
            </MenuItem>
          )}
          {(isUserAdmin || establishment.isAll || establishment.isParkingOrCarWash) && (
            <MenuItem
              to="/servicos"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Tabela de serviço
            </MenuItem>
          )}
        </Grid>
        {!userHasAllRestrictions() && (
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="primary"
              component="h6"
              className={classes.subMenuTitle}
            >
              Cadastro
            </Typography>
            {(isUserAdmin || !userRestrictions?.includes(RESTRICTION_PAGE.CREATE_ESTABLISHMENT)) && (
              <MenuItem
                to="/novo-estabelecimento"
                onClick={() => {
                  handleMenuClose();
                  setMobileMenuOpen(false);
                }}
                component={Link}
              >
                Novo estabelecimento
              </MenuItem>
            )}
            {(isUserAdmin || !userRestrictions?.includes(RESTRICTION_PAGE.CREATE_USER)) && (
              <MenuItem
                to="/novo-usuario"
                onClick={() => {
                  handleMenuClose();
                  setMobileMenuOpen(false);
                }}
                component={Link}
              >
                Novo usuário
              </MenuItem>
            )}
          </Grid>
        )}
      </Grid>
    </Menu>
  );

  const renderOperacionalMenu = (
    <Menu
      keepMounted
      anchorEl={anchorOperationalEl}
      id={operationalMenuId.menu}
      open={operationalMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      className={classes.menuListContent}
    >
      <></>
      {(isUserAdmin || establishment.isAll || establishment.isParkingOrCarWash) && (
        <MenuItem
          to="/historico-operacional"
          onClick={() => {
            handleMenuClose();
            setMobileMenuOpen(false);
          }}
          component={Link}
        >
          Histórico Operacional
        </MenuItem>
      )}
      {(isUserAdmin || establishment.isStore) && (
        <MenuItem
          to="/historico-de-venda"
          onClick={() => {
            handleMenuClose();
            setMobileMenuOpen(false);
          }}
          component={Link}
        >
          Histórico de Pedidos
        </MenuItem>
      )}
      <MenuItem
        to="/registro-de-atividades"
        onClick={() => {
          handleMenuClose();
          setMobileMenuOpen(false);
        }}
        component={Link}
      >
        Registro de Atividades
      </MenuItem>
      {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.SESSION_REGISTER)) && (
        <MenuItem
          to="/registro-de-sessoes"
          onClick={() => {
            handleMenuClose();
            setMobileMenuOpen(false);
          }}
          component={Link}
        >
          Registro de Sessões
        </MenuItem>
      )}
      {(isUserAdmin || establishment.isAll || !establishment.isStoreOnly) && (
        <MenuItem
          to="/historico-de-avarias"
          onClick={() => {
            handleMenuClose();
            setMobileMenuOpen(false);
          }}
          component={Link}
        >
          Dossiê de Avarias
        </MenuItem>
      )}
    </Menu>
  );

  const renderProductsMenu = (
    <Menu
      keepMounted
      anchorEl={anchorProductsEl}
      id={productsMenuId.menu}
      open={productsMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      MenuListProps={{ disablePadding: true }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Controle
          </Typography>
          <MenuItem
            to="/produtos"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Listagem de produto
          </MenuItem>
          <MenuItem
            to="/fornecedores"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Fornecedores
          </MenuItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Operações
          </Typography>
          <MenuItem
            to={handleProductButtonLink}
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Cadastrar produto
          </MenuItem>
        </Grid>
      </Grid>
    </Menu>
  );

  const renderFinantialMenu = (
    <Menu
      keepMounted
      anchorEl={anchorFinantialEl}
      id={finantialMenuId.menu}
      open={finantialMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      className={classes.menuListContent}
      MenuListProps={{ disablePadding: true }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={(isUserAdmin || premiumPackageEstablishments?.includes(establishmentId)) ? 6 : 12}
        >
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Relatórios
          </Typography>
          <MenuItem
            to="/fluxo-de-caixa"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Fluxo de caixa
          </MenuItem>
          <MenuItem
            to="/transacoes"
            onClick={() => {
              handleMenuClose()
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Transações
          </MenuItem>
          {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.PROVISIONAL_RECEIPT)) && (
            <MenuItem
              to="/notas-fiscais"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Notas fiscais
            </MenuItem>
          )}
        </Grid>
        {(isUserAdmin || premiumPackageEstablishments?.includes(establishmentId)) && (
          <Grid item xs={12} md={6}>
            {(isUserAdmin || !(!userRestrictions?.includes(SITE_RESTRICTIONS.PAYROLL_SHEET) || !userRestrictions?.includes(SITE_RESTRICTIONS.COMISSION))) ? (
              <Typography
                variant="h6"
                color="primary"
                component="h6"
                className={classes.subMenuTitle}
              >
                <span>&#8203;</span>
              </Typography>
            ) : (
              <Typography
                variant="h6"
                color="primary"
                component="h6"
                className={classes.subMenuTitle}
              >
                Operações
              </Typography>
            )}
            {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.PAYROLL_SHEET)) && (
              <MenuItem
                to="/funcionarios"
                onClick={() => {
                  handleMenuClose();
                  setMobileMenuOpen(false);
                }}
                component={Link}
              >
                Folha de pagamento
              </MenuItem>
            )}
            {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.COMISSION)) && (
              <MenuItem
                to="/comissionamento"
                onClick={() => {
                  handleMenuClose();
                  setMobileMenuOpen(false);
                }}
                component={Link}
              >
                Comissionamento
              </MenuItem>
            )}
          </Grid>
        )}
      </Grid>
    </Menu>
  );

  const renderClientsMenu = (
    <Menu
      keepMounted
      anchorEl={anchorClientsEl}
      id={clientsMenuId.menu}
      open={clientsMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      MenuListProps={{ disablePadding: true }}
    >
      <Grid container style={{ minWidth: 270 }}>
        <Grid
          item
          xs={12}
          md={(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.CREATE_CLIENT)) ? 6 : 12}
        >
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Controle
          </Typography>
          <MenuItem
            to="/clientes"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Clientes
          </MenuItem>
          {(isUserAdmin || establishmentTypes?.includes(2)) && (
            <MenuItem
              to="/lembretes"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Lembretes
            </MenuItem>
          )}
        </Grid>
        {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.CREATE_CLIENT)) && (
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="primary"
              component="h6"
              className={classes.subMenuTitle}
            >
              Cadastro
            </Typography>
            <MenuItem
              to="/tipos-de-cliente"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Novo Cliente
            </MenuItem>
          </Grid>
        )}
      </Grid>
    </Menu>
  );

  const renderConfigurationMenu = (
    <Menu
      keepMounted
      anchorEl={anchorConfigurationEl}
      id={configurationsMenuId.menu}
      open={configurationsMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      className={classes.menuListSectionsContent}
      MenuListProps={{ disablePadding: true }}
    >
      <Grid container>
        {(isUserAdmin || !establishment.isStoreOnly) && (
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="primary"
              component="h6"
              className={classes.subMenuTitle}
            >
              Operacional
            </Typography>
            <MenuItem
              to="/comprovantes"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Comprovantes
            </MenuItem>
            <MenuItem
              to="/operacao"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Operação
            </MenuItem>
            <MenuItem
              to="/caixa"
              onClick={() => {
                handleMenuClose()
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Caixa
            </MenuItem>
          </Grid>
        )}
        <Grid item xs={12} md={(isUserAdmin || !establishment.isStoreOnly) ? 6 : 12}>
          <Typography
            variant="h6"
            color="primary"
            component="h6"
            className={classes.subMenuTitle}
          >
            Financeiro
          </Typography>
          {(isUserAdmin || !userRestrictions?.includes(SITE_RESTRICTIONS.PROVISIONAL_RECEIPT)) && (
            <MenuItem
              to="/configuracoes-de-notas-fiscais"
              onClick={() => {
                handleMenuClose();
                setMobileMenuOpen(false);
              }}
              component={Link}
            >
              Nota fiscal
            </MenuItem>
          )}
          <MenuItem
            to="/categorias"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Categorias
          </MenuItem>
          <MenuItem
            to="/centros-de-custo"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Centros de custo
          </MenuItem>
          <MenuItem
            to="/contas"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Contas
          </MenuItem>
          <MenuItem
            to="/meios-de-pagamento"
            onClick={() => {
              handleMenuClose();
              setMobileMenuOpen(false);
            }}
            component={Link}
          >
            Meios de pagamento
          </MenuItem>
        </Grid>
      </Grid>
    </Menu>
  );

  const renderProfileMenu = (
    <Menu
      keepMounted
      anchorEl={anchorProfileEl}
      id={profileMenuId.menu}
      open={profileMenuId.isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: positionDropdownMenu }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      className={classes.menuListContent}
      MenuListProps={{ minWidth: '180px' }}
    >
      <MenuItem
        to="/perfil"
        onClick={() => {
          handleMenuClose();
          setMobileMenuOpen(false);
        }}
        component={Link}
      >
        Perfil
      </MenuItem>
      <MenuItem
        to="/"
        onClick={logout}
        component={Link}
      >
        Sair
      </MenuItem>
      <MenuItem className={classes.versionItem}>
        <Typography variant="subtitle1" component="subtitle1">
          Versão {projectPackage.version}
        </Typography>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Collapse in={mobileMenuOpen} timeout="auto" unmountOnExit>
      <Box pt={2}>
        <List component="div" disablePadding>
          {(isUserAdmin || !isLoginFromNoAccessCode) && (
            <ListItem
              button
              to="/"
              className={classes.menuMobileItem}
              component={Link}
              onClick={handleOpenMenu}
            >
              Visão Geral
            </ListItem>
          )}
          {(isUserAdmin || !isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.BUSINESS)) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={businessMenuId.desktop}
            >
              Negócio
              <ExpandMore />
            </ListItem>
          )}
          {(isUserAdmin || !isLoginFromNoAccessCode) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={operationalMenuId.desktop}
            >
              Operacional
              <ExpandMore />
            </ListItem>
          )}
          {(isUserAdmin || (!isLoginFromNoAccessCode && (premiumPackageEstablishments?.includes(establishmentId) || establishment.isStore))) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={productsMenuId.desktop}
            >
              Produtos
              <ExpandMore />
            </ListItem>
          )}
          {(isUserAdmin || (!isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.FINANCIAL))) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={finantialMenuId.desktop}
            >
              Financeiro
              <ExpandMore />
            </ListItem>
          )}
          {(isUserAdmin || (!isLoginFromNoAccessCode && (!userRestrictions?.includes(SITE_RESTRICTIONS.CLIENT) && !userRestrictions?.includes(SITE_RESTRICTIONS.CLIENTS)))) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={clientsMenuId.desktop}
            >
              Clientes
              <ExpandMore />
            </ListItem>
          )}
          {(isUserAdmin || (!isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.CONFIG))) && (
            <ListItem
              button
              className={classes.menuMobileItem}
              onClick={handleOpenMenu}
              id={configurationsMenuId.desktop}
            >
              Configurações
              <ExpandMore />
            </ListItem>
          )}
          <ListItem
            button
            className={classes.menuMobileItem}
            onClick={handleOpenMenu}
            id={profileMenuId.desktop}
          >
            Perfil
            <ExpandMore />
          </ListItem>
        </List>
      </Box>
    </Collapse>
  );

  return (
    <AppBar position="relative" elevation={0} className={classes.root}>
      {(isDebitWarning &&
        isDebitWarningModalOpen &&
        history.location.pathname != '/cobranca/boletos') &&
      (
        <Modal
          id="isDebitWarningHeaderModal"
          title="Débito em Aberto"
          open={isDebitWarning && isDebitWarningModalOpen}
          onClose={() => setIsDebitWarningModalOpen(false)}
        >
          <Typography>Estabelecimento(s) com débito: {debitWarningEstablishmentNames}</Typography>
          <Typography>Por favor, entre na sua área do cliente ou entre em contato com o financeiro da Jump.</Typography>
          <CustomButton
            type="button"
            color="success"
            style={{ marginTop: 15 }}
            onClick={() => {
              history.push('/cobranca/boletos');
              setIsDebitWarningModalOpen(false);
            }}
          >
            Área do Cliente
          </CustomButton>
        </Modal>
      )}
      <Container maxWidth="lg" disableGutters className={classes.container}>
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.menuRow}
          >
            <Grid item>
              <Box to="/" component={Link} className={classes.logo}>
                <img
                  style={{ marginTop: '10px' }}
                  src={isResale ? process.env.REACT_APP_RESALE_HEADER_LOGO : logo}
                  alt="Logo"
                  width="120"
                />
              </Box>
            </Grid>
            <Grid item className={classes.sectionDesktop}>
              {(isUserAdmin || !isLoginFromNoAccessCode) && (
                <Typography
                  to="/"
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  component={Link}
                >
                  Visão Geral
                </Typography>
              )}
              {(isUserAdmin || (!isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.BUSINESS))) && (
                <Typography
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  onClick={handleOpenMenu}
                  id={businessMenuId.desktop}
                >
                  Negócio
                  <ExpandMore />
                </Typography>
              )}
              {(isUserAdmin || !isLoginFromNoAccessCode) && (
                <Typography
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  onClick={handleOpenMenu}
                  id={operationalMenuId.desktop}
                >
                  Operacional
                  <ExpandMore />
                </Typography>
              )}
              {(isUserAdmin || (!isLoginFromNoAccessCode && (premiumPackageEstablishments?.includes(establishmentId) || establishment.isStore))) && (
                <Typography
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  onClick={handleOpenMenu}
                  id={productsMenuId.desktop}
                >
                  Produtos
                  <ExpandMore />
                </Typography>
              )}
              {(isUserAdmin || (!isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.FINANCIAL))) && (
                <Typography
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  onClick={handleOpenMenu}
                  id={finantialMenuId.desktop}
                >
                  Financeiro
                  <ExpandMore />
                </Typography>
              )}
              {(isUserAdmin || (!isLoginFromNoAccessCode && (!userRestrictions?.includes(SITE_RESTRICTIONS.CLIENT) && !userRestrictions?.includes(SITE_RESTRICTIONS.CLIENTS)))) && (
                <Typography
                  variant="button"
                  color="inherit"
                  className={classes.menuItem}
                  onClick={handleOpenMenu}
                  id={clientsMenuId.desktop}
                >
                  Clientes
                  <ExpandMore />
                </Typography>
              )}
            </Grid>
            <Grid item className={classes.sectionDesktop}>
              {(isUserAdmin || (!isLoginFromNoAccessCode && !userRestrictions?.includes(SITE_RESTRICTIONS.CONFIG))) && (
                <Button
                  id={configurationsMenuId.desktop}
                  edge="end"
                  aria-label="Configurações do sistema"
                  aria-controls={businessMenuId}
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  color="inherit"
                  startIcon={<SettingsIcon />}
                />
              )}
              <Button
                id={profileMenuId.desktop}
                edge="end"
                aria-label="Configurações de perfil"
                aria-controls={businessMenuId}
                aria-haspopup="true"
                onClick={handleOpenMenu}
                color="inherit"
                startIcon={<Person />}
              />
            </Grid>
            <Grid item className={classes.sectionMobile}>
              <IconButton
                aria-label="Mostrar menu em smarthphones"
                aria-controls={businessMenuId.mobile}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {/* Mobile menu */}
              {renderMobileMenu}
            </Grid>
            {/* Desktop menu lists */}
            {renderBusinessMenu}
            {renderOperacionalMenu}
            {renderProductsMenu}
            {renderFinantialMenu}
            {renderClientsMenu}
            {renderConfigurationMenu}
            {renderProfileMenu}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;